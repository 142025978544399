import React from 'react';
import { Remarkable } from 'remarkable';
import hljs from './hljs';
import './Markdown.scss';

type MarkdownProps = {
  value: string,
  className: string
};

export const Markdown = ({ value, className }: MarkdownProps) => {
  // @ts-ignore
  const md = new Remarkable({
    langPrefix: 'hljs language-',
    highlight: (str: string, lang: string) => {
      if (lang && hljs.getLanguage(lang)) {
        return hljs.highlight(lang, str).value;
      }
      return hljs.highlightAuto(str).value;
    },
  });

  return (
    <div className={`markdown ${className}`}>
      <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: md.render(value) }} />
    </div>
  );
};
