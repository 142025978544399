import React from 'react';
import './Error.scss';

type ErrorType = React.HTMLProps<HTMLDivElement> & {
  children: React.ReactChild | undefined,
};

export const Error = ({ children, ...props }: ErrorType) => (
  <div className="error" {...props}>{children}</div>
);
