import axios, { AxiosResponse } from 'axios';

axios.defaults.withCredentials = true;

// const successHandler = (response: AxiosResponse) => response.data;
// const errorHandler = (error: AxiosResponse) => {
//   throw new Error(error.statusText);
// };

// export const generateHttpHandler = (
//   handler: Promise<AxiosResponse>,
//   onSuccess = successHandler,
//   onError = errorHandler,
// ) => handler.then(onSuccess).catch(onError);

export class HttpAdapter {
  static get(url: string, config?: object): Promise<AxiosResponse> {
    return axios.get(url, config);
  }

  static post(url: string, body: object, config?: object): Promise<AxiosResponse> {
    return axios.post(url, body, config);
  }

  static put(url: string, body: object, config?: object): Promise<AxiosResponse> {
    return axios.put(url, body, config);
  }

  static delete(url: string, config?: object): Promise<AxiosResponse> {
    return axios.delete(url, config);
  }
}
