import React from 'react';

export const ProceedIcon = () => {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 5.5L0.75 10.2631V0.73686L9 5.5Z" fill="#C4C4C4" />
    </svg>
  );
};
