import React, { useEffect } from 'react';

import './TestQuestion.scss';
import { Question } from '../../components/Question/Question';
import { Timer } from '../../components/Timer/Timer';
import { Answers } from '../../types';
import { Logo } from '../../shared/logo';
import { Markdown } from '../../components/MarkDown/MarkDown';
import { TestQuestions } from './TestQuestions';

type TestQuestionProps = {
  activeQuestion: number;
  userTypedAnswer: string;
  setUserTypedAnswer: any;
  handleNext: () => void;
  userChosenAnswer: Answers[]
  chosenAnswer: Answers | undefined;
  takenAnswers: Answers[];
  timer: number;
  testQuestions: any;
  setChosenAnswer: React.Dispatch<React.SetStateAction<Answers | undefined>>,
  allQuestionsLength: any
};

const hints: Record<number, string> = {
  1: 'Please select one best answer',
  3: 'Please select all correct answers',
};

export const TestQuestion = ({
  activeQuestion,
  testQuestions,
  userTypedAnswer,
  setUserTypedAnswer,
  timer,
  setChosenAnswer,
  handleNext,
  chosenAnswer,
  allQuestionsLength,
} :TestQuestionProps) => {

  useEffect(() => {
    setUserTypedAnswer('');
  }, [activeQuestion]);

  return (
    <div className="test-page" data-testid="testPage">
      <div className="test-page__logo">
        <Logo />
      </div>
      <>
        <Timer data-testid="timer" timer={timer} />
        <div className="test-page__questions">
          <Question
            data-testid="question"
            questionNumber={`Question ${activeQuestion + 1}/${
              allQuestionsLength
            }`}
            handleNext={handleNext}
            buttonTitle={
              activeQuestion + 1 === allQuestionsLength ? 'Finish' : 'Next'
            }
          >
            <div className="test-page__questions-container">
              <Markdown
                className="textarea-with-md__markdown questionHeader"
                value={testQuestions?.body}
              />
              <div>
                {testQuestions?.questionType === 1 || testQuestions?.questionType === 3 ? (
                  <div className="hint">
                    {testQuestions?.questionType && hints[testQuestions?.questionType]}
                  </div>
                ) : null}
              </div>

              <TestQuestions
                questionType={testQuestions?.questionType}
                testQuestions={testQuestions}
                activeQuestion={activeQuestion}
                userTypedAnswer={userTypedAnswer}
                setUserTypedAnswer={setUserTypedAnswer}
                chosenAnswer={chosenAnswer}
                setChosenAnswer={setChosenAnswer}
              />
            </div>
          </Question>
        </div>
      </>
    </div>
  );
};
