import { HttpAdapter } from '../HttpAdapter';
import { basicConfig } from '../../config';

export class UserService {
  static registerUser = (name: string,
    email: string,
    phone: string,
    university: string,
    assessment: string,
    utmSource: string,
    utmMedium: string,
    utmCampaign: string,
    utmId: string,
    utmTerm: string,
    utmContent: string,
    file?: File) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('university', university);
    formData.append('file', file || '');
    formData.append('assessment', assessment);
    formData.append('utmSource', utmSource);
    formData.append('utmMedium', utmMedium);
    formData.append('utmCampaign', utmCampaign);
    formData.append('utmId', utmId);
    formData.append('utmTerm', utmTerm);
    formData.append('utmContent', utmContent);

    return HttpAdapter.post(`${basicConfig.gridFitApi}/api/auth/passwordless`, formData);
  };

  // TODO: add User | Error interface
  static getUserProfile = (): Promise<any> => {
    return HttpAdapter.get(`${basicConfig.gridFitApi}/api/auth/candidate/profile`);
  };
}
