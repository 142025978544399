import { createContext } from 'react';
import { configure } from 'mobx';

import { UserActions } from './userStore/UserActions';
import { UserStore } from './userStore/UserStore';

configure({ enforceActions: 'observed' });

const userStore = new UserStore();
const userActions = new UserActions(userStore);

export const storesContext = createContext({
  userActions,
  userStore,
});
