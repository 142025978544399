import { useEffect, useState } from 'react';

export const useMaxActiveQuestion = (
  activeQuestion: number,
  userId: string,
  assessmentId: string,
) => {
  const [maxActiveQuestion, setMaxActiveQuestion] = useState<number>(activeQuestion);

  useEffect(() => {
    setMaxActiveQuestion((prevValue: any) => {
      if (prevValue >= activeQuestion) {
        return prevValue;
      }
      return activeQuestion;
    });
  }, [activeQuestion]);

  const getMaxActiveQuestion = (id: string) => {
    const indexOfLastAnswered = localStorage.getItem(
      `maxQuestion:${id}${userId}${assessmentId}`,
    );
    return !indexOfLastAnswered || indexOfLastAnswered === undefined
      ? 0
      : JSON.parse(indexOfLastAnswered);
  };

  return { maxActiveQuestion, getMaxActiveQuestion };
};
