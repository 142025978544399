import { HttpAdapter } from '../HttpAdapter';
import { basicConfig } from '../../config';

export class ReportService {
  static getReport = (reportId: string) => {
    return HttpAdapter.get(`${basicConfig.gridFitApi}/api/report/${reportId}`);
  };

  static createReport = (data: any) => {
    return HttpAdapter.post(`${basicConfig.gridFitApi}/api/report/add`, data);
  };

  static updateReport = (reportId: string, data: any) => {
    return HttpAdapter.put(`${basicConfig.gridFitApi}/api/report/${reportId}`, data);
  };
}
