import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './NotFoundPage.scss';
import moment from 'moment';
import { Button } from '../../components/Button/Button';
import { NotFoundImage } from '../../shared/404';
import { useStores } from '../../custom-hooks/store/use-stores';

type PageData = {
  title: string
  description: string | React.ReactNode
};

type State = {
  invalidAssesment?: {
    status: string,
    date?: Date
  }
};

export const NotFoundPage = () => {

  const history = useHistory();
  const userId = useStores().userStore.user?._id;

  const location = useLocation<State>();
  const { invalidAssesment } = location.state || {};

  const [pageData, setPageData] = useState<PageData>({
    title: 'Sorry, this page not found',
    description: 'Looks this page went on vacation!',
  });

  useEffect(() => {
    setPageData({
      title:
      invalidAssesment?.status === 'finished'
        ? 'We are sorry, the assessment is closed'
        : invalidAssesment?.status === 'notStarted'
          ? 'This assessment has not started yet'
          : 'Sorry, this page not found',
      description: invalidAssesment?.status === 'finished' ? (
        <>
          This assessment was closed on the {' '}
          {moment(invalidAssesment?.date).subtract(1, 'd').format('DD MMMM YYYY')}
          . If you are interested in applying for other assessments please
          visit <a target="_blank" href="https://portal.griddynamics.net/internship" rel="noreferrer">our portal</a>.
        </>
      )
        : invalidAssesment?.status === 'notStarted'
          ? `This assessment will start on the ${moment(invalidAssesment?.date).format('DD MMMM YYYY')}. Please try again later.`
          : 'Looks this page went on vacation!',
    });
  }, [invalidAssesment]);

  const handleClick = () => {
    if (!invalidAssesment && Boolean(userId)) {
      history.replace('/disciplines');
    } else {
      history.replace('/signup');
    }
  };

  return (
    <div className="not-found-page">
      <div className="not-found-page__img">
        <NotFoundImage />
      </div>
      <div className="not-found-page__title">{pageData.title}</div>
      <div className="not-found-page__description">{pageData.description}</div>
      <Button className="not-found-page__btn" title="Take me home" onClick={handleClick} />
    </div>
  );
};
