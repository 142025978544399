import React from 'react';
import './Radio.scss';

type RadioType = {
  checked: boolean | undefined,
  onChange: (checked: boolean) => void,
  children: React.ReactChild
};

export const Radio = ({ checked, onChange, children }: RadioType) => {
  return (
    <div onKeyDown={() => { onChange(!checked); }} data-testid="radioLabel" onClick={() => { onChange(!checked); }}>
      <input type="radio" className="radio__input" data-testid="radio-input" checked={checked} onChange={() => { onChange(!checked); }} />
      <label htmlFor="radio">
        {children}
      </label>
    </div>
  );
};
