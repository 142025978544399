import React from 'react';
import './CheckBox.scss';

type CheckBoxType = {
  checked: boolean,
  onChange: (checked: boolean) => void,
  children: React.ReactChild,
};

export const CheckBox = ({
  checked, onChange, children,
}: CheckBoxType) => {
  return (
    <div className="checkbox__container" data-testid="agreeCheckboxLabel" role="button" tabIndex={0} onKeyDown={() => { onChange(!checked); }} onClick={() => { onChange(!checked); }}>
      <label className="checkbox__wrapper" htmlFor="agree">
        <input type="checkbox" className="checkbox__input" data-testid="agreeCheckbox" checked={checked} onChange={() => { onChange(!checked); }} />
        <span className="checkbox__checkmark" aria-label="agree" />
        {children}
      </label>
    </div>
  );
};
