import React from 'react';
import './Question.scss';
import { Button } from '../Button/Button';

type QuestionProps = {
  buttonTitle: string,
  questionNumber: string,
  handleNext: () => void,
  children: React.ReactChild,
};

export const Question = ({
  children, handleNext, buttonTitle, questionNumber,
}: QuestionProps) => (
  <div className="question" data-testid="question">
    <div className="question__number">{questionNumber}</div>
    {children}
    <Button title={buttonTitle} onClick={handleNext} />
  </div>
);
