import { HttpAdapter } from '../HttpAdapter';
import { basicConfig } from '../../config';

export class AssessmentService {
  static getAssessment = (assessmentId: string, config = {}) => {
    return HttpAdapter.get(`${basicConfig.gridFitApi}/api/assessment/${assessmentId}`, config);
  };

  static sendAssessmentResult = (body = {}) => {
    return HttpAdapter.post(`${basicConfig.gridFitApi}/api/assessment/results`, body);
  };

  static getAssessmentType = (assessmentId: string, config = {}) => {
    return HttpAdapter.get(`${basicConfig.gridFitApi}/api/assessment/type/${assessmentId}`, config);
  };
}
