import React from 'react';
import { useHistory } from 'react-router-dom';
import './ResultPage.scss';
import { Button } from '../../components/Button/Button';
import { Logo } from '../../shared/logo';

export const ResultPage = () => {
  const history = useHistory();

  return (
    <div className="result-page">
      <div className="test-page__logo"><Logo /></div>
      <div className="result-page__wrapper">
        <div className="result-page__title"> You’ve completed the test!</div>
        <div className="result-page__subtitle">Return to the main page to take more tests. If you have already completed general knowledge and all the specialization tests you can go back and review your results.</div>
        <Button title="Return to the main page" onClick={() => { history.replace('/disciplines', { sendResults: true }); }} />
      </div>
    </div>
  );
};
