import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import './Signup.scss';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import { RegForm } from '../../components/RegForm/RegForm';
import logo from '../../assets/images/logo.svg';
import { UserService, AssessmentService } from '../../services';

interface Params {
  assessmentId: string
}
export const SignUpPage = () => {
  const { assessmentId }: Params = useParams();
  const { search } = useLocation();

  const utmSource = new URLSearchParams(search).get('utm_source') || ''; // eslint-disable-line
  const utmMedium = new URLSearchParams(search).get('utm_medium') || ''; // eslint-disable-line
  const utmCampaign = new URLSearchParams(search).get('utm_campaign') || ''; // eslint-disable-line
  const utmId = new URLSearchParams(search).get('utm_id') || ''; // eslint-disable-line
  const utmTerm = new URLSearchParams(search).get('utm_term') || ''; // eslint-disable-line
  const utmContent = new URLSearchParams(search).get('utm_content') || ''; // eslint-disable-line

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  const [disableBtn, setDisableBtn] = useState(false);

  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSubmit = async (values: any, isScreening:boolean) => {
    try {
      const assessment = await AssessmentService.getAssessment(assessmentId, { headers: { bypass: true } });
      const notYetStartedAssessment = (new Date().getTime() - new Date(assessment.data.startDate).getTime() < 0);
      const alreadyFinishedAssessment = (new Date().getTime() - new Date(assessment.data.endDate).getTime() > 0);
      if (notYetStartedAssessment && !values.email.endsWith('@griddynamics.com')) {
        setDisableBtn(true);
        setSnackbar({
          open: true,
          message: `The assessment is not started yet, please try again on 
          ${moment(assessment.data.startDate).format('DD MMMM YYYY')}
          `,
          severity: 'warning',
        });
      } else if (alreadyFinishedAssessment) {
        setDisableBtn(true);
        setSnackbar({
          open: true,
          message: 'The assessment is already finished',
          severity: 'warning',
        });
      } else if (isScreening) {
        setDisableBtn(true);
        setSnackbar({
          open: true,
          message: 'You can’t self-apply to this assessment. Please contact coordinator',
          severity: 'warning',
        });
      } else {
        await UserService.registerUser(
          values.name,
          values.email,
          values.phone,
          values.university,
          assessmentId,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
          values.file,
        );
        setDisableBtn(true);
        setSnackbar({
          open: true,
          message: 'The signup link was sent to your email.',
          severity: 'success',
        });
      }
    } catch (err) {
      setDisableBtn(false);
      setSnackbar({
        open: true,
        message: 'Oops, it seems this assessment id is missing or does not exist.',
        severity: 'error',
      });
    }
  };

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <div className="signup-page">
      <div className="signup-page__logo">
        <img src={logo} alt="logo" />
      </div>
      <RegForm
        onSubmit={handleSubmit}
        disableBtn={disableBtn}
      />
      <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert elevation={6} variant="filled" onClose={handleClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
