import React, { useEffect, useState, useRef } from 'react';
import './EducationTaskPage.scss';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { Controlled as CodeMirror } from 'react-codemirror2';
import MuiAlert from '@material-ui/lab/Alert';
import { Logo } from '../../shared/logo';
import { Button } from '../../components/Button/Button';
import { AllQuestionsImage } from './allquestionsIcon';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { Popup } from '../../components/Popup/Popup';
import { TestService } from '../../services/dataServices/TestService';
import { QuestionService } from '../../services/dataServices/QuestionService';
import { useStores } from '../../custom-hooks/store/use-stores';
import { Spinner } from '../../components/Spinner/Spinner';
import { ReportService } from '../../services/dataServices/ReportService';
import { ValidationService } from '../../services/dataServices/ValidationService';
import { Timer } from '../../components/Timer/Timer';
import { AnswersCodingTask } from '../../types';
import { Markdown } from '../../components/MarkDown/MarkDown';
import { ResultPage } from '../ResultPage/ResultPage';
import { setIntervalWorker } from './worker_script';
import { getTimerValue } from './getTimerValue';
import { getTaskTime } from './getTaskTime';
import { useMaxActiveQuestion } from '../../hooks/useMaxActiveQuestion';
import { Info } from '../../shared/info';

const createWorker = () => {
  return new Worker(setIntervalWorker);
};

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const EducationTaskPage = () => {
  const {
    userStore: {
      user: { _id: userId, assessment: assessmentId },
    },
  } = useStores();

  const history = useHistory();
  const { path } = useRouteMatch();
  const { testId } = useParams<{ testId: string }>();
  const [openPopup, setOpenPopup] = useState(false);
  const [test, setTest] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [userChosenAnswer, setUserChosenAnswer] = useState<AnswersCodingTask[]>([]);
  const match = useRouteMatch();
  const params = useParams<{ testId: string; }>();
  const disciplineID: string = params.testId;
  const { maxActiveQuestion, getMaxActiveQuestion } = useMaxActiveQuestion(activeQuestion, userId, assessmentId);
  const [timerId, setTimerId] = useState<number | undefined>();
  const [timer, setTimer] = useState<number>(60000);
  const [initialTimer, setInitialTimer] = useState<number>(60000);
  const [currentQuestionInfo, setCurrentQuestionInfo] = useState<any>({});
  const [codeValidationResult, setCodeValidationResult] = useState<any>({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const timerWorker = useRef(createWorker());
  const testStartedTimeQuery = `testStartedTime:${disciplineID}${userId}${assessmentId}`;

  const isTaskCompleted = (question: number, testInfo: any) => question + 2 > testInfo?.report.answers.length;

  useEffect(() => {
    timerWorker.current.postMessage({ turn: 'on' });
    for (const key in localStorage) { // eslint-disable-line
      if (key.startsWith('timer')) {
        localStorage.removeItem(key);
      }
    }
  }, []);

  useEffect(() => {
    TestService.getTest(testId, {
      params: { populate: true, candidate: userId },
    })
      .then((resp) => {
        setTest(resp.data);
        setTimer(resp.data.duration * 1000 * 60);
        setInitialTimer(resp.data.duration * 1000 * 60);
        setActiveQuestion(getMaxActiveQuestion(resp.data._id));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!test || !maxActiveQuestion) {
      return;
    }
    localStorage.setItem(`maxQuestion:${test?._id}${userId}${assessmentId}`, maxActiveQuestion.toString());
  }, [maxActiveQuestion, test?._id]);

  useEffect(() => {
    if (timerId) {
      localStorage.setItem('test', `${disciplineID}${userId}${assessmentId}`);
    }
  }, [timerId, timer]);

  useEffect(() => {
    if (timer - 1000 < 0) {
      clearInterval(timerId!);

      const preparedUserAnswers = [...userChosenAnswer];
      const questionMessage = preparedUserAnswers[activeQuestion].code.message;
      preparedUserAnswers[activeQuestion].code.message = questionMessage === '' || questionMessage === ' ' ? 'No answer is provided yet' : questionMessage;

      const data = {
        assessment: assessmentId,
        candidate: userId,
        test: `${testId}`,
        answers: [...preparedUserAnswers],
        isCompleted: isTaskCompleted(activeQuestion, test),
        time: isTaskCompleted(activeQuestion, test)
          ? getTaskTime(localStorage.getItem(testStartedTimeQuery), new Date().getTime().toString())
          : '',
      };

      setLoading(true);
      ReportService.updateReport(test.report._id, data).finally(() => {
        setLoading(false);
      });

      history.replace(`${match.url}/results`);
      localStorage.removeItem(testStartedTimeQuery);
      localStorage.removeItem(`maxQuestion:${test?._id}${userId}${assessmentId}`);
      localStorage.removeItem('test');
      timerWorker.current.postMessage({ turn: 'off' });
    }
  }, [timer, timerId]);

  useEffect(() => {
    if (!test?.report.answers.length) {
      return;
    }
    const question = test?.report.answers[activeQuestion];
    if (!question) {
      const preparedUserAnswers = [...userChosenAnswer];
      const questionMessage = preparedUserAnswers[activeQuestion].code.message;
      preparedUserAnswers[activeQuestion].code.message = questionMessage === '' || questionMessage === ' ' ? 'No answer is provided' : questionMessage;

      const data = {
        assessment: assessmentId,
        candidate: userId,
        test: `${testId}`,
        answers: [...preparedUserAnswers],
        isCompleted: isTaskCompleted(activeQuestion, test),
        time: isTaskCompleted(activeQuestion, test)
          ? getTaskTime(localStorage.getItem(testStartedTimeQuery), new Date().getTime().toString())
          : '',
      };

      setLoading(true);
      console.log(data);
      ReportService.updateReport(test?.report._id, data).finally(() => {
        setLoading(false);
      });

      history.replace(`${match.url}/results`);
      localStorage.removeItem(`maxQuestion:${test?._id}${userId}${assessmentId}`);
      localStorage.removeItem('test');
      localStorage.removeItem(testStartedTimeQuery);
      clearInterval(timerId!);
      timerWorker.current.postMessage({ turn: 'off' });
      return;
    }

    setTimer(getTimerValue(disciplineID, test, userId, assessmentId));
    setTimerId(undefined);

    const handleMessage = () => {
      setTimer((prevTimer) => prevTimer - 1000);
    };

    timerWorker.current.addEventListener('message', handleMessage);
    const timeoutId = new Date().getTime();
    setTimerId(timeoutId);

    return () => {
      timerWorker.current.removeEventListener('message', handleMessage);
      clearInterval(timeoutId);
    };
  }, [activeQuestion, history, test]);

  useEffect(() => {
    if (!test) {
      return;
    }

    const preparedAnswers = test.report.answers.map(
      (currAnswer: any, index: number) => {
        const message = currAnswer?.code.message || '';
        return {
          questionId: currAnswer.questionId,
          answered: Boolean(currAnswer?.code.message) && currAnswer?.code.message !== 'No answer is provided yet',
          visited: index < getMaxActiveQuestion(test._id),
          code: {
            message,
            languageId: currAnswer.code?.languageId || null,
          },
        };

      },
    );
    setUserChosenAnswer(preparedAnswers);
  }, [test]);

  useEffect(() => {
    if (activeQuestion < test?.report.answers.length) {
      history.replace(`${match.url}/question/${activeQuestion + 1}`);
    }
  }, [activeQuestion, disciplineID, test]);

  useEffect(() => {
    if (!test) {
      return;
    }

    const questionID = test?.report.answers[activeQuestion].questionId;
    async function fetchData() {
      const question = await QuestionService.findOrGetQuestion(test?.questions, questionID);
      setCurrentQuestionInfo(question);
    }

    fetchData();
    setCodeValidationResult({});
  }, [activeQuestion, test]);

  const handleNext = async (answerChanged?: any) => {
    const preparedUserAnswers = [...userChosenAnswer];
    preparedUserAnswers[activeQuestion].visited = true;
    const questionMessage = preparedUserAnswers[activeQuestion].code.message;
    preparedUserAnswers[activeQuestion].code.message = questionMessage === '' || questionMessage === ' ' ? 'No answer is provided yet' : questionMessage;
    setUserChosenAnswer(preparedUserAnswers);

    const data = {
      assessment: assessmentId,
      candidate: userId,
      test: `${testId}`,
      answers: preparedUserAnswers,
      isCompleted: isTaskCompleted(activeQuestion, test),
      time: isTaskCompleted(activeQuestion, test)
        ? getTaskTime(localStorage.getItem(testStartedTimeQuery), new Date().getTime().toString())
        : '',
    };

    setLoading(true);
    try {
      console.log(data);
      await ReportService.updateReport(test.report._id, data);
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Oops, unable to update the report',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }

    if (data.isCompleted && !answerChanged) {
      history.replace(`${match.url}/results`);
      localStorage.removeItem(test?._id);
      localStorage.removeItem('test');
      localStorage.removeItem(testStartedTimeQuery);
      clearInterval(timerId!);
      timerWorker.current.postMessage({ turn: 'off' });
      return;
    }

    if (!answerChanged) {
      setActiveQuestion(activeQuestion + 1);
    }
  };

  const navigateToQuestion = (index: number) => {
    handleNext(true);
    setActiveQuestion(index);
    setOpenPopup(false);
  };

  const handlePrev = () => {
    navigateToQuestion(activeQuestion - 1);
  };

  const handleChange = (editor: any, data: any, value: string) => {
    const nextAnswers = [...userChosenAnswer];

    if (!nextAnswers[activeQuestion]) {
      return;
    }

    nextAnswers[activeQuestion] = {
      ...nextAnswers[activeQuestion],
      code: {
        ...nextAnswers[activeQuestion].code,
        message: value,
      },
      answered: Boolean(value) && value !== 'No answer is provided yet',
      visited: true,
    };

    setUserChosenAnswer(nextAnswers);
  };

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  if (loading) {
    return <Spinner />;
  }

  const getValidationResults = async () => {
    const body = {
      code: userChosenAnswer[activeQuestion].code.message,
      languageId: currentQuestionInfo.code.languageId,
    };
    try {
      const validationResults = await ValidationService.postValidation(body);
      setCodeValidationResult(JSON.stringify(validationResults?.data, null, 1));
    } catch (e: any) {
      setSnackbar({
        open: true,
        message: e.response.data.message,
        severity: 'error',
      });
    }
  };

  const openAnswersPopup = () => {
    setOpenPopup(true);
  };

  return (
    <>
      <Switch>
        <Route
          path={`${path}/results`}
          render={() => {
            return <ResultPage />;
          }}
        />
        <Route
          path={`${path}/question/:questionId`}
          render={() => {
            return (
              <div className="educationTask-page">
                <div className="educationTask-page__logo">
                  <Logo />
                </div>
                <div className="educationTask-page__container">
                  <h1>{test?.name}</h1>
                  <div
                    className="allQuestions"
                    onClick={() => {
                      openAnswersPopup();
                    }}
                  >
                    <div>
                      <AllQuestionsImage />
                    </div>
                    <div className="allQuestions__text">See all questions</div>
                  </div>
                  <Timer
                    data-testid="timer"
                    timer={timer}
                    redTimerTime={initialTimer <= 300000 ? 60000 : 300000}
                  />
                  <div className="task">
                    <div className="task-title">
                      Question {activeQuestion + 1} of{' '}
                      {test?.report.answers.length}
                    </div>
                    <div className="task-body">
                      <Markdown
                        className="textarea-with-md__markdown questionHeader"
                        value={currentQuestionInfo?.body}
                      />
                    </div>
                  </div>

                  <div className="answer">
                    <div className="answer-title">
                      <div>Your Answer</div>
                      <div className="answer-title__coding">
                        <Button
                          title="Validate"
                          onClick={() => getValidationResults()}
                          className="education-btn"
                        />
                        <div className="answer-title__coding-hint">
                          <div>
                            <Info />
                          </div>
                          <div className="answer-title__coding-hint__text">
                            Please note in order to run the function, please
                            call this function and pass parameters. Once you’re
                            done with validation, remove these lines
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <CodeMirror
                        value={
                          userChosenAnswer[activeQuestion]?.code?.message || ''
                        }
                        data-testid="codeMirror"
                        className="codeMirror"
                        options={{
                          lineNumbers: true,
                        }}
                        onBeforeChange={(editor, data, value) => {
                          handleChange(editor, data, value);
                        }}
                      />
                    </div>
                    {codeValidationResult.length ? (
                      <div className="code-validation">
                        <CodeMirror
                          value={codeValidationResult}
                          data-testid="codeMirror"
                          className="codeMirror"
                          options={{
                            lineNumbers: true,
                            readOnly: true,
                          }}
                          onBeforeChange={() => {}}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="btn-container">
                    {activeQuestion === 0 ? (
                      <div />
                    ) : (
                      <div className="btn-container__wrapper">
                        <Button
                          title="← Previous"
                          onClick={() => handlePrev()}
                          className="education-btn__prev"
                        />
                      </div>
                    )}
                    <div className="btn-container">
                      <Button
                        title={
                          activeQuestion + 1 === test?.report.answers.length
                            ? 'Finish'
                            : 'Next →'
                        }
                        onClick={() => handleNext()}
                        className="education-btn"
                      />
                    </div>
                  </div>
                </div>

                <Snackbar
                  open={snackbar.open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                >
                  <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={snackbar.severity}
                  >
                    {snackbar.message}
                  </Alert>
                </Snackbar>
                <Popup
                  title="All Questions"
                  isOpen={openPopup}
                  navigateToQuestion={(index) => navigateToQuestion(index)}
                  activeQuestion={activeQuestion}
                  onClose={() => setOpenPopup(false)}
                  questionsCollection={userChosenAnswer}
                  maxAnswered={getMaxActiveQuestion(test?._id)}
                />
              </div>
            );
          }}
        />
      </Switch>
    </>
  );
};
