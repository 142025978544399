import React from 'react';
import { Logo } from '../../shared/logo';
import './PrivacyPolicy.scss';

export const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy-policy__logo">
        <Logo />
      </div>
      <div>
        <h1>INFORMATION ON PERSONAL DATA PROCESSING</h1>
        <h4> Ⅰ. Personal data controller</h4>
        <div>
          Grid Dynamics Poland sp. z o.o. is a member of the Grid Dynamics
          Group. List of members of the Grid Dynamics Group:
          <table>
            <tbody>
              <tr>
                <td>Grid Dynamics Holdings, Inc.</td>
                <td>The United States</td>
              </tr>
              <tr>
                <td>Grid Dynamics LLC Beograd - Novi Beograd</td>
                <td>Serbia</td>
              </tr>
              <tr>
                <td>LLC “Grid Dynamics”</td>
                <td>Russia</td>
              </tr>
              <tr>
                <td>LLC “Grid Dynamics Ukraine”</td>
                <td>Ukraine</td>
              </tr>
              <tr>
                <td>Cometera Ukraine LLC</td>
                <td>Ukraine</td>
              </tr>
              <tr>
                <td>Grid Dynamics Poland LLC </td>
                <td>Poland</td>
              </tr>
              <tr>
                <td>Daxx Web Industries B.V. </td>
                <td>The Netherlands</td>
              </tr>
              <tr>
                <td>DX International B.V. </td>
                <td>The Netherlands</td>
              </tr>
              <tr>
                <td>DX4 Services B.V. </td>
                <td>The Netherlands</td>
              </tr>
              <tr>
                <td>Daxx Soft B.V. </td>
                <td>The Netherlands</td>
              </tr>
              <tr>
                <td>Tixx Soft LLC</td>
                <td>Ukraine</td>
              </tr>
              <tr>
                <td>Andy Soft LLC</td>
                <td>Ukraine</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Due to the scope of mutual connections and the organization of the personal data processing processes,
          all entities from the Grid Dynamics Group are co-administrators of your personal data.
        </p>
        <div>
          <strong>Contact point:</strong>
          <p>
            The entity to contact with regard to the processing of your personal data is Grid Dynamics Poland sp. z
            o.o. with its registered office in Kraków, al. 3 May 9, 30-062 Kraków, KRS number 0000511476, NIP number
            5252588225, REGON number 14727132800000.
          </p>
        </div>
        <p>
          You may contact it by electronic mail dpo@griddynamics.com or by traditional mail sending a letter to
          the address of Grid Dynamics Poland sp. z o.o. In the correspondence please add a note: “personal data
          protection”.
        </p>
        <h4>ⅠⅠ. Purpose and legal basis of personal data processing</h4>
        <p>
          You are reminded that provision of your personal data is voluntary but required for participation in the
          recruitments conducted by Grid Dynamics Group entities.
        </p>
        <p>
          Should you not provide your personal data, you will not be able to participate in the recruitments and, as
          a result, get employed.
        </p>
        <p>
          <strong>Article 6(1a) of GDPR<sup>1</sup> – consent of the data subject</strong>
        </p>
        <p>
          To the extent to which you gave your consent to the processing of your personal data for the purpose of
          using them in future recruitments conducted by Grid Dynamics Group entities.
        </p>
        <p>
          You may withdraw your consent to the processing of your personal data at any time. This however will
          not affect the legality of the processing that Grid Dynamics Group entities performed on the basis of your
          consent before its withdrawal. We will process the data for 3 years.
        </p>
        <p>
          <strong>
            Article 6(1)(b) GDPR – processing is necessary in order to take steps at the request of the data
            subject prior to entering into a contract – if the employment will have the form of a civil-law
            contract
          </strong>
        </p>
        <p>For the purpose of conducting the recruitment process.</p>
        <p>
          <strong>
            Article 6(1)(c) GDPR – processing is necessary for compliance with the legal obligations to which
            Grid Dynamics Group entities are subject – if the employment will have the form of an employment
            contract
          </strong>
        </p>
        <p>
          {' '}
          For the purpose of compliance with the obligations to which Grid Dynamics Group entities are subject
          under labor law regulations in connection with recruitment for the offered jobs
        </p>
        <p>
          <strong>
            Article 6(1)(f) of GDPR – processing is necessary for the purposes of the legitimate interests pursued
            by Grid Dynamics Group entities
          </strong>
        </p>
        <p>
          For the purpose of establishment, exercise or defense of legal claims in connection with litigations and
          proceedings before public authorities or other proceedings, including for exercise or defense of legal
          claims.
        </p>

        <p>
          For the purpose of making the personal data available within the Grid Dynamics Group.
        </p>
        <p>
          To ensure an efficient course of the recruitment processes through utilizing appropriate technical and
          organizational solutions.
        </p>
        <p>
          Providing your personal data to a client of a relevant Grid Dynamics Group entity to verify the adequacy
          of your qualifications to perform the duties defined by the client.
        </p>
        <div>
          The legitimate interests of the controller in the case of personal data processing pursuant to Article 6(1)(f)
          GDPR include:
          <ul>
            <li>exercise and defense of potential legal claims,</li>
            <li>transfer of data within a group of undertakings,</li>
            <li>verification of the adequacy of your qualifications.</li>
          </ul>
        </div>
        <h4> ⅠⅠⅠ. Recipients of personal data</h4>
        <div>
          Your personal data may be made available to the following recipients or recipient categories:
          <ul>
            <li>
              Public authorities and entities performing public duties or acting upon the order of public authorities to
              the extent and for the purposes following from the provisions of law;
            </li>
            <li>
              Entities providing services to Grid Dynamics Group entities in the area of payroll, recruitment services,
              immigration, benefit services, located in countries in which your personal data may be processed based
              on an intra-group agreement, i.e. The United States, Poland, Russia, Ukraine, Serbia, The Netherlands.
            </li>
          </ul>
        </div>
        <h4>IV. Transfer of personal data outside the European Economic Area</h4>
        <p>
          Your personal data will be transferred to Grid Dynamics Group entities located outside the European
          Economic Area:
        </p>
        <div>
          <table>
            <tbody>
              <tr>
                <td>Grid Dynamics Holdings, Inc.</td>
                <td>The United States</td>
              </tr>
              <tr>
                <td>Grid Dynamics LLC Beograd - Novi Beograd</td>
                <td>Serbia</td>
              </tr>
              <tr>
                <td>LLC “Grid Dynamics”</td>
                <td>Russia</td>
              </tr>
              <tr>
                <td>LLC “Grid Dynamics Ukraine”</td>
                <td>Ukraine</td>
              </tr>
              <tr>
                <td>Cometera Ukraine LLC</td>
                <td>Ukraine</td>
              </tr>
              <tr>
                <td>Tixx Soft LLC</td>
                <td>Ukraine</td>
              </tr>
              <tr>
                <td>Andy Soft LLC</td>
                <td>Ukraine</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Data transfers outside the European Economic Area are based on an intra-group agreement with Grid
          Dynamics Group entities.
        </p>
        <h4>V. Period of data processing</h4>
        <p>
          The personal data will be processed for a period necessary for the purposes specified in Section II.
        </p>
        <h4>VI. Rights of the data subjects</h4>
        <div>
          In connection with the processing of your personal data by Grid Dynamics Group entities, you have the
          right to:
          <ul>
            <li>access your personal data</li>
            <li>demand to have your personal data rectified (deleted)</li>
            <li>demand to have your personal data removed</li>
            <li>demand to limit your personal data processing</li>
            <li>move your personal data</li>
            <li>object to the processing of your personal data.</li>
          </ul>
        </div>
        <p>We will not make any automated decisions with regard to you</p>
        <h4>VII. Right to file a complaint with the President of the Personal Data Protection Office</h4>
        <p>
          If you deem that the processing of your personal data by Grid Dynamics Group entities breaches the
          provisions of GDPR, you have the right to file a complaint with the President of the Personal Data
          Protection Office (ul. Stawki 2, 00-193 Warsaw)
        </p>
      </div>
      <hr />
      <p className="explanation">
        <sup>1</sup> Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons
        with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC
        (General Data Protection Regulation)
      </p>
    </div>
  );
};
