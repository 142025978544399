const workercode = () => {
  let timerInterval;
  self.onmessage = function ({ data: { turn } }) {
    if (turn === 'off' || timerInterval) {
      clearInterval(timerInterval);
    }
    if (turn === 'on') {
      timerInterval = setInterval(() => {
        self.postMessage('');
      }, 1000);
    }
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
export const setIntervalWorker = URL.createObjectURL(blob);
