import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { observer } from 'mobx-react';

import { useStores } from '../../custom-hooks/store/use-stores';

interface PrivateRoutes {
  redirectToUrl?: string,
  path: string,
  component: FC,
}

export const PrivateRoute = observer(({ redirectToUrl = '/signup', component: Component, ...rest }: PrivateRoutes & RouteProps) => {
  const {
    userStore: {
      isAuthenticated,
    },
  } = useStores();

  return (
    <Route
      {...rest}
      render={props => (
        isAuthenticated ? <Component {...props} /> : <Redirect to={redirectToUrl} />
      )}
    />
  );
});
