import React from 'react';
import { Report } from '../../types';
import timerImg from '../../assets/images/alarm.svg';
import './Discipline.scss';

type DisciplineProps = {
  name: string;
  id: string;
  report: Report | null,
  showResults: boolean,
  optionalTests?: boolean,
  isButtonDisabled?: boolean,
  result: number,
  popupHandler: (id: string) => void;
  type?: string;
  duration?: number
};

export const Discipline = ({
  name, id, popupHandler, report, optionalTests, isButtonDisabled, showResults, result, type, duration,
}: DisciplineProps) => {
  const completedTest = <div className="discipline__completion" data-testid="finished-test">✓ You’ve completed this test</div>;
  const progressTest = <button onClick={() => { popupHandler(id); }} type="button" className="discipline__progress" data-testid="continue-test-btn">Continue</button>;
  const untouchedTest = <button onClick={() => { popupHandler(id); }} type="button" className={`${optionalTests !== undefined && !isButtonDisabled ? 'discipline__btn disabled-btn' : 'discipline__btn'}`} data-testid="start-test-btn">Start</button>;
  return (
    <div className="discipline" data-testid="discipline">
      {
        type === 'education' && (
          <div className="discipline__time"><img src={timerImg} alt="timer" /> {duration} min(s) </div>
        )
      }
      <div className="discipline__title">{name}</div>
      <div>
        {report === null ? untouchedTest : report.isCompleted === true ? completedTest : progressTest}
        {showResults && <div data-testid="result" className="discipline__score">Result: {result === 0 ? result : Math.round(result)}%</div>}
      </div>
    </div>
  );
};
