import {
  isPossiblePhoneNumber,
} from 'libphonenumber-js';

const emailValidation = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
const nameValidation = /^[a-zA-Z.\-\s]+$/;

export const getEmailError = (value: string): string => {
  if (value.length === 0) {
    return 'Please enter your email';
  } if (!emailValidation.test(value)) {
    return 'Enter email in example@mail.com format';
  }

  return '';
};

export const getNameError = (value: string): string => {
  if (value.length === 0) {
    return 'Please enter your first and last name';
  }

  if (!value.match(nameValidation)) {
    return 'Please use English letters, dots, dashes and spaces.';
  }

  return '';
};

export const getUniversityError = (value: string): string => {
  if (value.length === 0) {
    return '';
  }

  if (!value.match(nameValidation)) {
    return 'Please use English letters, dots, dashes and spaces.';
  }

  return '';
};

export const getAgreeError = (value: boolean): string => {
  if (!value) {
    return 'You have to accept Privacy Policy.';
  }

  return '';
};

export const getAllowContactError = (value: boolean): string => {
  if (!value) {
    return 'You need to allow us to contact you in case you qualify for an internship interview.';
  }

  return '';
};

export const getPhoneError = (value: any, country: any): string => {
  if (value.trim().length - 1 === 0) {
    return 'Please enter your phone number';
  }
  if (!isPossiblePhoneNumber(value, country.countryCode)) {
    return 'Please enter a valid phone number';
  }

  return '';
};

export const getFileError = (file: File): string => {
  if (!file) {
    return 'Please attach your CV';
  }

  if (file.size > 1e6) {
    return 'Please attach file less then 1MB';
  }

  if (file.name.split('.')[file.name.split('.').length - 1] !== 'pdf') {
    return 'Please attach pdf file.';
  }
  return '';
};
