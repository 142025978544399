import { observable, action, makeObservable } from 'mobx';

export class UserStore {
  @observable isAuthenticated: boolean | null = null;

  @observable user: any;

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      user: observable,
    });
  }

  @action.bound setUser = (value: any): void => {
    this.user = value;
  };

  @action.bound setUserAuthenticated = (value: boolean): void => {
    this.isAuthenticated = value;
  };
}
