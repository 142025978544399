import { HttpAdapter } from '../HttpAdapter';
import { basicConfig } from '../../config';
import { QuestionType } from '../../types';

export class QuestionService {
  static getQuestion = (questionID: string) => {
    return HttpAdapter.get(`${basicConfig.gridFitApi}/api/question/${questionID}`);
  };

  static findOrGetQuestion = async (testQuestions: QuestionType[], questionID: string | undefined) => {
    if (!questionID) {
      return;
    }

    const question = testQuestions.find((q: QuestionType) => q._id === questionID);

    if (question) {
      return question;
    }

    try {
      const response = await QuestionService.getQuestion(questionID);
      return response.data;
    } catch (e: any) {
      return {};
    }
  };
}
