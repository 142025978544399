export const getTaskTime = (testStartedTime: string | null, currentTime: string) => {
  if (Number.isNaN(Number(testStartedTime)) || Number.isNaN(Number(currentTime))) return '';
  if (testStartedTime === null) return '';
  if (testStartedTime.length === 0 || currentTime.length === 0) return '';

  const startedDate = Number(testStartedTime);
  const diffInSeconds = Math.round((Number(currentTime) - startedDate) / 1000);
  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds - minutes * 60;

  return `${minutes} mins ${seconds} secs`;
};
