import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { Answers, QuestionType } from '../../types';
import { PossibleAnswer } from '../../components/PossibleAnswerItem/PossibleAnswer';

import './TestQuestion.scss';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

require('codemirror/mode/javascript/javascript.js');

type TestQuestiontype = {
  questionType: number;
  testQuestions: QuestionType;
  activeQuestion: number;
  userTypedAnswer: string;
  setUserTypedAnswer: any;
  chosenAnswer: Answers | undefined;
  setChosenAnswer: React.Dispatch<React.SetStateAction<Answers | undefined>>
};

export const TestQuestions = ({
  questionType,
  testQuestions,
  userTypedAnswer,
  setChosenAnswer,
  chosenAnswer,
  setUserTypedAnswer }: TestQuestiontype) => {
  switch (questionType) {
    case 1:
      return (
        <div>
          {testQuestions?.answers.map((singleAnswer) => {
            return (
              <PossibleAnswer
                data-testid="possibleAnswer"
                handleChosenAnswer={(answer) => {
                  setChosenAnswer((prevQuestion) => {
                    const nextQuestion: any = { ...prevQuestion };
                    nextQuestion.answerIds.splice(0, 1, answer._id);
                    return nextQuestion;
                  });
                }}
                isSelectedAnswer={chosenAnswer?.answerIds?.includes(
                  singleAnswer._id,
                )}
                key={Math.random()}
                answer={singleAnswer}
              />
            );
          })}
        </div>
      );
    case 2:
      return (
        <CodeMirror
          value={userTypedAnswer}
          data-testid="codeMirror"
          className="codeMirror"
          options={{
            lineNumbers: true,
          }}
          onBeforeChange={(editor, data, value) => {
            setUserTypedAnswer(value);
            setChosenAnswer((prevQuestion) => {
              const nextQuestion: any = {
                ...prevQuestion,
                code: {
                  ...prevQuestion?.code,
                  message: value,
                },
              };
              return nextQuestion;
            });
          }}
        />
      );
    case 3:
      return (
        <div>{
          testQuestions?.answers.map((singleAnswer) => {
            return (
              <PossibleAnswer
                handleChosenAnswer={(answer, checked) => {
                  setChosenAnswer((prevQuestion) => {
                    const nextQuestion: any = { ...prevQuestion };
                    if (checked) {
                      if (!nextQuestion.answerIds.includes(answer._id)) {
                        nextQuestion.answerIds.push(answer._id);
                      }
                    } else {
                      nextQuestion.answerIds = nextQuestion.answerIds.filter(
                        (answerId: string) => answerId !== answer._id,
                      );
                    }

                    return nextQuestion;
                  });
                }}
                isSelectedAnswer={chosenAnswer?.answerIds?.includes(singleAnswer._id)}
                key={Math.random()}
                questionType={testQuestions?.questionType}
                answer={singleAnswer}
              />
            );
          })
        }
        </div>
      );
    default:
      return null;
  }
};
