import React from 'react';
import './Button.scss';

type ButtonType = {
  title: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean
};

export const Button = ({ title, onClick, className, disabled }: ButtonType) => (
  <button onClick={onClick} disabled={disabled} type="button" className={`btn ${className || ''} ${disabled ? 'disabled' : ''}`}>{title}</button>
);
