import React from 'react';
import './Input.scss';

type InputType = {
  label: string,
  placeholder: string,
  className?: string;
  type: string,
  htmlFor: string,
  name: string,
  id: string,
  inputStyle?: any,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  required?: boolean
};

export const Input = React.forwardRef(({
  label, placeholder, type, htmlFor, name, id, onChange, className, value, inputStyle, required,
}: InputType, ref: React.Ref<HTMLInputElement>) => {
  return (
    <div>
      <label htmlFor={htmlFor} className="input__label">
        <span className="input__label-text">{label}</span>
        {required && <span className="registration-form__asterics">*</span>}
        <input
          ref={ref}
          style={inputStyle}
          onChange={onChange}
          value={value}
          name={name}
          id={id}
          className={`${className} input__item`}
          data-testid={label}
          type={type}
          placeholder={placeholder}
        />
      </label>
    </div>
  );
});

Input.displayName = 'Input';

Input.defaultProps = {
  className: '',
};
