import { ChosenTest } from '../../types';

export const getTimerValue = (disciplineID: string, test: ChosenTest, userId: string, assessmentId: string) => {
  let nextTimer = test?.duration * 60000;

  const lastTimeClosed = Number(localStorage.getItem(`testStartedTime:${disciplineID}${userId}${assessmentId}`));
  let difference = 0;

  if (!isNaN(lastTimeClosed) && lastTimeClosed) {
    difference = new Date().getTime() - lastTimeClosed;
  }

  nextTimer -= difference;
  return nextTimer;
};
