import { action } from 'mobx';

import { UserService } from '../../services';
import { UserStore } from './UserStore';

export class UserActions {
  constructor(private readonly userStore: UserStore) {}

  @action getUserProfile = () => UserService.getUserProfile().then((user) => {
    if (user.data) {
      this.userStore.setUser(user.data);
      this.userStore.setUserAuthenticated(true);
    } else {
      throw new Error('no user');
    }
  }).catch(() => {
    this.userStore.setUserAuthenticated(false);
  });
}
