import React, { useEffect, useState } from 'react';
import './EducationPage.scss';
import { useHistory, useParams, useLocation } from 'react-router';
import { Logo } from '../../shared/logo';
import { Button } from '../../components/Button/Button';
import { useStores } from '../../custom-hooks/store/use-stores';
import { AssessmentService, ReportService, TestService } from '../../services';
import { ChosenTest, RandomConfig } from '../../types';

export const EducationTask = () => {
  const history = useHistory();
  const { testId } = useParams<any>();
  const [assessments, setAssessments] = useState<ChosenTest[]>([]);
  const [currentTest, setCurrentTest] = useState<ChosenTest>();
  const [randomQuestionNumber, setRandomQuestionNumber] = useState(0);
  const { search } = useLocation();

  const {
    userStore: {
      user: { assessment: assessmentId, _id: userId },
    },
  } = useStores();

  const getAssessments = async () => {
    const receivedAssessment = await AssessmentService.getAssessment(assessmentId, { // eslint-disable-line
      params: { candidate: userId },
    });

    setAssessments(receivedAssessment.data.tests);
  };

  const getCurrentTest = async () => {
    const receivedAssessment = await TestService.getTest(testId);
    const randomQNumber = receivedAssessment.data.randomConfig
      .map((c: RandomConfig) => c.random - 1)
      .reduce((prev: number, curr: number) => prev + curr, 0);
    setRandomQuestionNumber(receivedAssessment.data.questions.length + randomQNumber);
    setCurrentTest(receivedAssessment.data);
  };

  useEffect(() => {
    getAssessments();
    getCurrentTest();
  }, []);

  const handleTestStart = (id: string) => {
    const foundChosenTest = assessments.find(
      (elem: ChosenTest) => elem._id === id,
    );

    if (!foundChosenTest) {
      return;
    }

    if (foundChosenTest.report) {
      history.push(
        `/education-tasks/${id}?report=${foundChosenTest.report._id}`,
      );
      if (!localStorage.getItem(`testStartedTime:${id}${userId}${assessmentId}`)) {
        localStorage.setItem(`testStartedTime:${id}${userId}${assessmentId}`, new Date().getTime().toString());
      }
      return;
    }

    const data = {
      assessment: assessmentId,
      candidate: userId,
      test: `${id}`,
      answers: [],
    };

    ReportService.createReport(data).then((res) => {
      localStorage.setItem(`testStartedTime:${id}${userId}${assessmentId}`, new Date().getTime().toString());
      history.push(`/education-tasks/${id}?report=${res.data._id}`);
    });
  };

  return (
    <div className="education-page">
      <div className="education-page__logo">
        <Logo />
      </div>
      <div className="education-page__container">
        <h1>{currentTest && currentTest.name}</h1>
        <div className="education-page__instruction">
          <div>Instruction:</div>
          <ul>
            {/* here is issue */}
            <li>
              Number of question: <span>{randomQuestionNumber}</span>
            </li>
            <li>
              Questions displayed per page: <span>1</span>
            </li>
            {
              (currentTest && currentTest?.questions.length > 1) && <li> Will allow you to go back and change your answers.</li>
            }
          </ul>
        </div>
        <div className="start-btn-container">
          <Button
            title={search.includes('isCompleted') ? 'Continue' : 'Start →'}
            onClick={() => handleTestStart(testId)}
            className="start-education-btn"
          />
        </div>
      </div>
    </div>
  );
};
