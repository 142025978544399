import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PrivateRoute } from './components/routes';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';
import { SignUpPage } from './pages/SignupPage/Signup';
import { DisciplinesPage } from './pages/DisciplinesPage/Disciplines';
import { Test } from './pages/TestPage/Test';
import { PrivacyPolicy } from './pages/PrivacyPolicyPage/PrivacyPolicy';
import { useStores } from './custom-hooks/store/use-stores';
import { EducationTask } from './pages/EducationPage/EducationPage';
import { EducationTaskPage } from './pages/EducationTaskPage/EducationTaskPage';

export const App = observer(() => {
  const {
    userActions: {
      getUserProfile,
    },
    userStore: {
      isAuthenticated,
    },
  } = useStores();

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  if (isAuthenticated === null) {
    return null;
  }

  return (
    <Switch>
      <PrivateRoute exact path="/disciplines" component={() => <DisciplinesPage />} />
      <PrivateRoute path="/test/:testId" component={Test} />
      <Route path="/signup/:assessmentId?" component={SignUpPage} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/404" component={() => <NotFoundPage />} />
      <Route path="/education/:testId" component={() => <EducationTask />} />
      <Route path="/education-tasks/:testId" component={() => <EducationTaskPage />} />
      <Redirect from="*" to="/404" />
    </Switch>
  );
});
