import React from 'react';

export const NotFoundImage = () => {
  return (
    <svg viewBox="0 0 530 247" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path d="M157 16H147V233H157V16Z" fill="#FF900A" />
        <path d="M187 154H177V186.5H187V154Z" fill="#FF900A" />
        <path d="M124 72L64 158.5H49L123 48H125.5L124 72Z" fill="#FF900A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M147.034 186.295H177V153.52H147.034V16H113.206L14 158.1V186.295H112.542V233H147.034V186.295ZM112.542 93.6518V153.52H53.3927L109.505 73.5004C110.698 71.8701 111.903 70.0634 113.122 68.0862C112.734 75.1125 112.542 83.6405 112.542 93.6518Z" fill="#FFC174" />
      </g>
      <g filter="url(#filter1_d)">
        <path d="M388 119C388 179.199 339.199 228 279 228C218.801 228 170 179.199 170 119C170 58.801 218.801 10 279 10C339.199 10 388 58.801 388 119Z" fill="#FFC174" />
      </g>
      <path d="M225 55C225 59.4183 221.418 63 217 63C212.582 63 209 59.4183 209 55C209 50.5817 212.582 47 217 47C221.418 47 225 50.5817 225 55Z" fill="#FF900A" />
      <path d="M265 205.5C265 206.328 264.328 207 263.5 207C262.672 207 262 206.328 262 205.5C262 204.672 262.672 204 263.5 204C264.328 204 265 204.672 265 205.5Z" fill="#FA971F" />
      <path d="M331 34.5C331 35.3284 330.328 36 329.5 36C328.672 36 328 35.3284 328 34.5C328 33.6716 328.672 33 329.5 33C330.328 33 331 33.6716 331 34.5Z" fill="#FA971F" />
      <path d="M297 125.5C297 126.328 296.328 127 295.5 127C294.672 127 294 126.328 294 125.5C294 124.672 294.672 124 295.5 124C296.328 124 297 124.672 297 125.5Z" fill="#FA971F" />
      <path d="M211 64.5C211 65.3284 210.328 66 209.5 66C208.672 66 208 65.3284 208 64.5C208 63.6716 208.672 63 209.5 63C210.328 63 211 63.6716 211 64.5Z" fill="#FA971F" />
      <path d="M262 126C262 129.314 259.314 132 256 132C252.686 132 250 129.314 250 126C250 122.686 252.686 120 256 120C259.314 120 262 122.686 262 126Z" fill="#FA971F" />
      <path d="M199 136C199 140.418 195.418 144 191 144C186.582 144 183 140.418 183 136C183 131.582 186.582 128 191 128C195.418 128 199 131.582 199 136Z" fill="#FF900A" />
      <path d="M334 164C334 166.209 332.209 168 330 168C327.791 168 326 166.209 326 164C326 161.791 327.791 160 330 160C332.209 160 334 161.791 334 164Z" fill="#FA971F" />
      <path d="M347 131C347 143.15 337.15 153 325 153C312.85 153 303 143.15 303 131C303 118.85 312.85 109 325 109C337.15 109 347 118.85 347 131Z" fill="#FF900A" />
      <path d="M347.475 40.8014C348.842 39.0654 347.486 35.7199 344.448 33.329C341.41 30.9381 337.84 30.4073 336.474 32.1433C335.108 33.8793 336.463 37.2248 339.501 39.6157C342.539 42.0065 346.109 42.5374 347.475 40.8014Z" fill="#FA971F" />
      <path d="M259 201.5C259 207.299 254.299 212 248.5 212C242.701 212 238 207.299 238 201.5C238 195.701 242.701 191 248.5 191C254.299 191 259 195.701 259 201.5Z" fill="#FF900A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M382.83 114.084C383.158 113.323 384.04 112.972 384.801 113.3C398.465 119.187 407.704 125.201 413.303 131.014C418.908 136.833 421.007 142.617 419.92 147.894C419.364 150.592 417.602 152.838 415.034 154.67C412.474 156.496 409.022 157.981 404.855 159.167C396.519 161.541 385.004 162.807 371.185 162.999C343.523 163.383 306.298 159.464 266.049 151.172C225.799 142.88 190.057 131.768 164.799 120.482C152.181 114.844 142.103 109.129 135.384 103.654C132.026 100.917 129.442 98.1887 127.812 95.4997C126.177 92.8017 125.446 90.0425 126.002 87.3448C127.175 81.6505 132.924 77.8761 141.59 75.533C150.383 73.1555 162.808 72.0678 178.514 72.23C179.343 72.2385 180.007 72.917 179.999 73.7454C179.99 74.5738 179.312 75.2384 178.483 75.2298C162.877 75.0687 150.773 76.1579 142.373 78.429C133.846 80.7346 129.737 84.0807 128.94 87.9501C128.583 89.6821 128.997 91.6672 130.378 93.9448C131.764 96.2314 134.066 98.7097 137.279 101.328C143.703 106.563 153.508 112.151 166.022 117.743C191.029 128.916 226.552 139.972 266.654 148.234C306.756 156.495 343.756 160.379 371.143 159.999C384.849 159.809 396.064 158.551 404.034 156.282C408.02 155.146 411.115 153.78 413.292 152.227C415.46 150.681 416.625 149.021 416.981 147.289C417.798 143.325 416.348 138.5 411.142 133.095C405.931 127.685 397.096 121.863 383.614 116.055C382.853 115.727 382.502 114.845 382.83 114.084Z" fill="#F78802" />
      <g filter="url(#filter2_d)">
        <path d="M490 16H480V233H490V16Z" fill="#FF900A" />
        <path d="M520 154H510V186.5H520V154Z" fill="#FF900A" />
        <path d="M457 72L397 158.5H382L456 48H458.5L457 72Z" fill="#FF900A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M480.034 186.295H510V153.52H480.034V16H446.206L347 158.1V186.295H445.542V233H480.034V186.295ZM445.542 93.6518V153.52H386.393L442.505 73.5004C443.698 71.8701 444.903 70.0634 446.122 68.0862C445.734 75.1125 445.542 83.6405 445.542 93.6518Z" fill="#FFC174" />
      </g>
      <defs>
        <filter id="filter0_d" x="0" y="6" width="197" height="241" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter1_d" x="156" y="0" width="242" height="242" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter2_d" x="333" y="6" width="197" height="241" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
