import React from 'react';
import './Timer.scss';

 type TimerProps = {
   timer: number,
   redTimerTime?: number
 };

 type TimerImgProps = {
   className: string
 };

let defaltRedTimer = 6000;

const TimerSvg = ({ className }: TimerImgProps) => (
  <svg className="timerA" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path data-testid="timer-logo" className={className} d="M20.9983 38.5C12.3475 38.386 5.3623 31.4008 5.24828 22.75C5.3623 14.0992 12.3475 7.11402 20.9983 7C29.6491 7.11402 36.6343 14.0992 36.7483 22.75C36.6343 31.4008 29.6491 38.386 20.9983 38.5ZM20.9983 10.5C14.2698 10.5886 8.83686 16.0215 8.74828 22.75C8.83686 29.4785 14.2698 34.9114 20.9983 35C27.7267 34.9114 33.1597 29.4785 33.2483 22.75C33.1597 16.0215 27.7267 10.5886 20.9983 10.5ZM29.7483 24.5H19.2483V14H22.7483V21H29.7483V24.5ZM35.5093 11.739L30.2435 6.489L32.7093 4.00925L37.9768 9.25925L35.5093 11.7372V11.739ZM6.48553 11.739L4.00928 9.25925L9.24353 4.00925L11.7198 6.489L6.48903 11.739H6.48553Z" fill="black" />
  </svg>
);

const timerToHuman = (milliseconds : number) => {
  const microsecond = Math.floor(milliseconds / 1000);
  const seconds = microsecond % 60;
  const minutes = Math.floor(microsecond / 60);

  if (!minutes) {
    return `${seconds} sec`;
  }

  if (!seconds) {
    return `${minutes} min`;
  }

  return `${minutes} min ${seconds} sec`;
};

export const Timer = ({ timer, redTimerTime } : TimerProps) => {
  if (redTimerTime) {
    defaltRedTimer = redTimerTime;
  }

  return (
    <div className="timer" data-testid="timer">
      <TimerSvg className={timer < defaltRedTimer ? 'timer__redtimerImg' : 'timer__timerImg'} />
      <div data-testid="time" className={timer < defaltRedTimer ? 'timer__redtime' : 'timer__time'}>{timerToHuman(timer)}</div>
    </div>
  );
};
